<template>
  <FormSection
    :icon="isActive ? 'eva-shield' : 'eva-shield-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Security Details</template>
    <template #description>
      Designate users and owners who have access and management privileges for
      the files and documents under this root folder.
    </template>

    <!-- repository owner -->

    <MultiSelectField
      label="owner"
      :value="owner"
      :options="users"
      class="q-mb-lg"
      @input="updateOwner"
    />

    <!-- ... -->

    <!-- repository coordinator -->

    <MultiSelectField
      label="coordinator"
      :value="coordinator"
      :options="users"
      class="q-mb-lg"
      @input="updateCoordinator"
    />

    <!-- ... -->

    <!-- expiry date -->

    <DateField
      v-if="false"
      :value="expiryDate"
      label="expiry date"
      @input="updateExpiryDate"
    />

    <!-- ... -->
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";

import { user } from "@/api/factory.js";

export default {
  name: "SecurityDetails",

  components: { FormSection, MultiSelectField, DateField },

  props: {
    owner: {
      type: Array,
      required: true,
    },

    coordinator: {
      type: Array,
      required: true,
    },

    expiryDate: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },
  },

  data() {
    return {
      users: [],
    };
  },

  mounted() {
    this.getUsers();
  },

  methods: {
    updateOwner(owner) {
      this.$emit("update:owner", owner || []);
    },

    updateCoordinator(coordinator) {
      this.$emit("update:coordinator", coordinator || []);
    },

    updateExpiryDate(expiryDate) {
      this.$emit("update:expiryDate", expiryDate);
    },

    /* api functions */

    async getUsers() {
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      this.users =
        payload &&
        payload.map((user) => ({
          id: this.$nano.id(),
          label: user.value || user.loginName,
          value: Number(user.id),
        }));
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped></style>
