<template>
  <FormSection
    :icon="isActive ? 'mdi-database' : 'mdi-database-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Lookup Details</template>
    <template #description>
      Enter the desired name of the folder you'd like to create as a root folder
      to organize your documents.
    </template>

    <!-- Connection -->

    <ValidationProvider v-slot="{ errors }" name="Connection">
      <SelectField
        :value="connection"
        label="Connection"
        is-mandatory
        :options="connections"
        :error="errors[0]"
        class="q-mb-lg"
        @input="updateConnection"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- Connection name-->

    <ValidationProvider
      v-if="connection"
      v-slot="{ errors }"
      name="Connection name"
      :rules="{ required: connection ? true : false }"
    >
      <SelectField
        :value="connectionId"
        label="Connection Name"
        is-mandatory
        :options="connectionNameList"
        :error="errors[0]"
        class="q-mb-lg"
        @input="updateConnectionId"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- Connection name-->

    <ValidationProvider
      v-if="connectionId"
      v-slot="{ errors }"
      name="Function name"
      :rules="{ required: connectionId ? true : false }"
    >
      <SelectField
        :value="hubName"
        label="Function Name"
        is-mandatory
        :options="husList"
        :error="errors[0]"
        class="q-mb-lg"
        @input="updateHubName"
      />
    </ValidationProvider>

    <!-- ... -->
    <div id="lookup-field">
      <div v-if="hubName" class="q-pb-sm">
        <FormFieldLabel label="Name Condition" />

        <table>
          <thead>
            <tr>
              <th class="medium">Column</th>
              <th class="medium">Field</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(value, index) in conditionMapping" :key="index">
              <td>
                <!-- <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <TextField v-model="name" is-mandatory :error="errors[0]" />
                </ValidationProvider> -->
                {{ value.name }}
              </td>
              <td>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <SelectField
                    v-model="value.field"
                    is-mandatory
                    :error="errors[0]"
                    :options="fields"
                  />
                </ValidationProvider>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="hubName" class="q-pb-sm">
        <FormFieldLabel label="Value Condition" />

        <table>
          <thead>
            <tr>
              <th class="medium">Column</th>
              <th class="medium">Field</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(value, index) in valueMapping" :key="index">
              <td>
                <!-- <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <TextField v-model="name" is-mandatory :error="errors[0]" />
                </ValidationProvider> -->
                {{ value.name }}
              </td>
              <td>
                <!-- <TextField
                    v-model="value.field"
                    is-mandatory
                    :error="errors[0]"
                  /> -->
                <SelectField
                  v-model="value.field"
                  is-mandatory
                  :options="outDataColumns"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </FormSection>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FormSection from "@/components/common/item-builder/FormSection.vue";
// import TextField from "@/components/common/form/text-field/TextField.vue";
// import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import { connector } from "@/api/factory.js";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";

export default {
  name: "LookupDetails",

  components: {
    FormSection,
    ValidationProvider,
    // TextField,
    SelectField,
    FormFieldLabel,
    // TextAreaField,
  },

  props: {
    connection: {
      type: String,
      required: true,
    },

    connectionId: {
      type: [Number, String],
      required: true,
    },

    hubName: {
      type: Number,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
    },

    conditionMapping: {
      type: Array,
      required: true,
    },

    valueMapping: {
      type: Array,
      required: true,
    },

    outDataColumns: {
      type: Array,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },
  },

  data() {
    return {
      connections: [],
      sqlConnections: [],
      apiConnections: [],
      oracleConnections: [],
      husList: [],
      connectorHub: [],
      inData: [],
      outData: [],
      field: [],
      name: "",
    };
  },

  computed: {
    connectionNameList() {
      if (this.connection === "sql") {
        return this.sqlConnections;
      } else if (this.connection === "api") {
        return this.apiConnections;
      } else if (this.connection === "oracle") {
        return this.oracleConnections;
      }
      return [];
    },
  },

  watch: {
    connectionId() {
      if (this.connectionId) {
        this.getLookUp();
      }
    },

    // hubName() {
    //   this.inData = [];
    //   this.outData = [];
    //   if (this.hubName) {
    //     let data = this.connectorHub.find((name) => name.id === this.hubName);
    //     if (data) {
    //       let inData = JSON.parse(data.inDataPoint);
    //       inData.forEach((item) => {
    //         this.inData.push({
    //           name: item,
    //           field: "",
    //         });
    //       });
    //       let outData = data.outDataPoint ? JSON.parse(data.outDataPoint) : [];
    //       outData.forEach((item) => {
    //         // this.outData.push({
    //         //   name: item,
    //         //   field: "",
    //         // });
    //         this.outData.push({
    //           id: this.$nano.id(),
    //           label: item,
    //           value: item,
    //         });
    //       });
    //     }
    //   }
    // },
  },

  created() {
    this.getConnection();
  },

  methods: {
    updateName(name) {
      this.$emit("update:name", name);
    },

    updateConnection(connection) {
      this.$emit("update:connection", connection);
      this.connectionId = 0;
    },

    updateConnectionId(id) {
      this.$emit("update:connectionId", id);
    },

    updateHubName(id) {
      this.$emit("update:hubName", id);
    },

    async getConnection() {
      const { error, payload } = await connector.getConnection({
        filterBy: [],
        mode: "BROWSE",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        // console.log(payload);

        let sql = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "SQL"
        );
        if (sql.length) {
          this.connections.push({
            id: this.$nano.id(),
            label: "SQL",
            value: "sql",
          });
          sql.forEach((item) => {
            this.sqlConnections.push({
              id: this.$nano.id(),
              label: item.name,
              value: item.id,
            });
          });
        }

        let api = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "oauth2"
        );
        if (api.length) {
          this.connections.push({
            id: this.$nano.id(),
            label: "API",
            value: "api",
          });
          api.forEach((item) => {
            this.apiConnections.push({
              id: this.$nano.id(),
              label: item.name,
              value: item.id,
            });
          });
        }

        let oracle = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "ORACLE"
        );
        if (oracle.length) {
          this.connections.push({
            id: this.$nano.id(),
            label: "Oracle",
            value: "oracle",
          });

          oracle.forEach((item) => {
            this.oracleConnections.push({
              id: this.$nano.id(),
              label: item.name,
              value: item.id,
            });
          });
        }
      }
    },

    async getLookUp() {
      this.husList = [];

      this.$store.commit("showLoadingBar");

      const { error, payload } = await connector.getLookUp({
        filterBy: [],
        mode: "BROWSE",
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      let result = [];
      payload.forEach((item) => {
        result.push(item.connectorHub);
      });

      const result1 = result.filter(
        (item) =>
          item.createdBy === this.$store.state.session.id &&
          item.connectorId === this.connectionId
      );
      result1.forEach((item) => {
        this.husList.push({
          id: this.$nano.id(),
          label: item.name,
          value: item.id,
        });
      });
      this.connectorHub = result1;
      this.$emit("connectorHub", this.connectorHub);
    },
  },
};
</script>

<style lang="scss" scoped>
#lookup-field {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 70px;
      }

      &.medium {
        width: 140px;
      }

      &.large {
        width: 210px;
      }

      &.actions {
        width: 48px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
    }

    td {
      padding: 4px;
      // vertical-align: top;
      // text-align: center;
      vertical-align: middle;
    }

    td .label {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 4px;
      color: var(--icon-color);
    }
  }
}
</style>
