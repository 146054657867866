<template>
  <div id="boolean-field-options">
    <div class="row q-col-gutter-md">
      <!-- truthy value -->

      <ValidationProvider
        v-slot="{ errors }"
        name="truthy value"
        :rules="{ required: true }"
        class="col"
      >
        <TextField
          :value="truthyValue"
          label="truthy value"
          is-mandatory
          :error="errors[0]"
          class="q-mb-lg"
          @input="updateTruthyValue"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- falsy value -->

      <ValidationProvider
        v-slot="{ errors }"
        name="falsy value"
        :rules="{ required: true }"
        class="col"
      >
        <TextField
          :value="falsyValue"
          label="falsy value"
          is-mandatory
          :error="errors[0]"
          class="q-mb-lg"
          @input="updateFalsyValue"
        />
      </ValidationProvider>

      <!-- ... -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";

export default {
  name: "BooleanFieldOptions",

  components: { ValidationProvider, TextField },

  props: {
    truthyValue: {
      type: String,
      required: true,
    },

    falsyValue: {
      type: String,
      required: true,
    },
  },

  methods: {
    updateTruthyValue(truthyValue) {
      this.$emit("update:truthyValue", truthyValue);
    },

    updateFalsyValue(falsyValue) {
      this.$emit("update:falsyValue", falsyValue);
    },
  },
};
</script>

<style lang="scss" scoped></style>
