<template>
  <div id="fields-builder">
    <!-- label -->

    <FormFieldLabel label="fields" is-mandatory />

    <!-- ... -->

    <!-- header -->

    <FieldsHeader
      :fields-type="fieldsType"
      @add="showFieldSheet"
      @clear="clearAllFields"
    />

    <!-- ... -->

    <!-- folder fields -->

    <FolderFields
      :folder-fields="folderFields"
      :fields-type="fieldsType"
      @edit="editField"
      @delete="deleteField"
      @reorder="reorderFields"
    />

    <!-- ... -->

    <BaseSeparator
      v-if="folderFields.length && otherFields.length"
      class="q-my-md"
    />

    <!-- other fields -->

    <OtherFields
      v-if="fieldsType === 'STATIC'"
      :other-fields="otherFields"
      @edit="editField"
      @delete="deleteField"
    />

    <!-- ... -->

    <!-- bottom header -->

    <FieldsHeader
      v-if="value.length && fieldsType === 'STATIC'"
      :fields-type="fieldsType"
      position="BOTTOM"
      @add="showFieldSheet"
      @clear="clearAllFields"
    />

    <!-- ... -->

    <!-- error -->

    <FormFieldError v-if="error" :error="error" />

    <!-- ... -->

    <!-- field details-->

    <FieldDetails
      v-model="isFieldDetailsVisible"
      :field="field"
      :fields="value"
      @add="addField"
      @save="saveField"
    />

    <!-- ... -->
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FieldsHeader from "./components/FieldsHeader.vue";
import FolderFields from "./components/FolderFields.vue";
import OtherFields from "./components/OtherFields.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import FieldDetails from "./components/field-details/FieldDetails.vue";
import { lowerCase } from "lodash-es";

export default {
  name: "FieldsBuilder",

  components: {
    FormFieldLabel,
    FieldsHeader,
    FieldDetails,
    FolderFields,
    OtherFields,
    FormFieldError,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    fieldsType: {
      type: String,
      required: true,
    },

    error: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isFieldDetailsVisible: false,
      field: null,
    };
  },

  computed: {
    folderFields: {
      cache: false,
      get() {
        return this.value
          .filter((field) => field.includeInFolderStructure)
          .sort((a, b) => {
            if (a.level < b.level) return -1;
            if (a.level > b.level) return 1;
            return 0;
          });
      },
    },

    otherFields: {
      cache: false,
      get() {
        return this.value.filter((field) => !field.includeInFolderStructure);
      },
    },
  },

  watch: {
    isFieldDetailsVisible() {
      if (!this.isFieldDetailsVisible) {
        this.field = null;
      }
    },
  },

  methods: {
    showFieldSheet() {
      this.isFieldDetailsVisible = true;
    },

    addField(field) {
      const isAlreadyExists = this.value.find(
        (_field) => lowerCase(_field.name) === lowerCase(field.name)
      );

      if (isAlreadyExists) {
        this.$alert.error("Field with the given name already exists");
        return;
      }

      this.$emit("input", [...this.value, field]);
    },

    clearAllFields() {
      this.$emit("input", []);
    },

    editField(fieldId) {
      this.field = this.value.find((field) => field._id === fieldId);
      this.showFieldSheet();
    },

    saveField(field) {
      const fieldIdx = this.value.findIndex(
        (_field) => _field._id === field._id
      );

      const fields = this.value;
      fields[fieldIdx] = field;

      this.$emit("input", fields);
    },

    deleteField(fieldId) {
      const fieldIdx = this.value.findIndex((field) => field._id === fieldId);

      const fields = this.value;
      fields.splice(fieldIdx, 1);

      this.$emit("input", fields);
    },

    reorderFields(fields) {
      this.$emit("input", [...fields, ...this.otherFields]);
    },
  },
};
</script>

<style lang="scss" scoped></style>
