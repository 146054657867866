<template>
  <div id="other-fields">
    <div
      v-for="field in otherFields"
      :key="field._id"
      class="field row items-center"
    >
      <div class="col row">
        <BaseIcon name="mdi-label-outline" />
        <div class="q-ml-md">{{ field.name }}</div>
      </div>

      <div style="width: 140px">{{ field.dataType }}</div>

      <div style="width: 100px">{{ field.isMandatory ? "Yes" : "No" }}</div>

      <BaseActionButton
        v-tooltip:secondary.left="'edit field'"
        is-flat
        color="secondary"
        icon="eva-edit-outline"
        no-border
        @click="handleEdit(field._id)"
      />

      <BaseActionButton
        v-tooltip:red.right="'delete field'"
        is-flat
        color="red"
        icon="eva-close-outline"
        no-border
        @click="handleDelete(field._id)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "OtherFields",

  props: {
    otherFields: {
      type: Array,
      required: true,
    },
  },

  methods: {
    handleEdit(fieldId) {
      this.$emit("edit", fieldId);
    },

    handleDelete(fieldId) {
      this.$emit("delete", fieldId);
    },
  },
};
</script>

<style lang="scss" scoped>
#other-fields {
  .field {
    margin-bottom: 8px;
  }
}
</style>
