<template>
  <FormSection
    :icon="isActive ? 'mdi-file-code' : 'mdi-file-code-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Document Properties</template>
    <template #description>
      Tagging the document properties with index fields allows easy
      identification your documents even after downloading them from the
      application
    </template>

    <!-- title -->

    <MultiSelectField
      :value="title"
      label="title"
      :options="_fields"
      class="q-mb-lg"
      @input="updateTitle"
    />

    <!-- ... -->

    <!-- subject -->

    <MultiSelectField
      :value="subject"
      label="subject"
      :options="_fields"
      class="q-mb-lg"
      @input="updateSubject"
    />

    <!-- ... -->

    <!-- author -->

    <MultiSelectField
      :value="author"
      label="author"
      :options="_fields"
      class="q-mb-lg"
      @input="updateAuthor"
    />

    <!-- ... -->

    <!-- keywords -->

    <MultiSelectField
      :value="keywords"
      label="keywords"
      :options="_fields"
      class="q-mb-lg"
      @input="updateKeywords"
    />

    <!-- ... -->

    <!-- signature -->

    <TextField :value="signature" label="signature" @input="updateSignature" />

    <!-- ... -->
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";

export default {
  name: "MetadataDetails",

  components: { FormSection, MultiSelectField, TextField },

  props: {
    title: {
      type: Array,
      required: true,
    },

    subject: {
      type: Array,
      required: true,
    },

    author: {
      type: Array,
      required: true,
    },

    keywords: {
      type: Array,
      required: true,
    },

    signature: {
      type: String,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },
  },

  computed: {
    _fields() {
      return this.fields
        .filter((field) => field.isMandatory)
        .map((field) => ({
          id: field._id,
          label: field.name,
          value: field.name,
        }));
    },
  },

  methods: {
    updateTitle(title) {
      this.$emit("update:title", title);
    },

    updateSubject(subject) {
      this.$emit("update:subject", subject);
    },

    updateAuthor(author) {
      this.$emit("update:author", author);
    },

    updateKeywords(keywords) {
      this.$emit("update:keywords", keywords);
    },

    updateSignature(signature) {
      this.$emit("update:signature", signature);
    },
  },
};
</script>

<style lang="scss" scoped></style>
