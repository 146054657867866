<template>
  <div id="cloud-file-servers">
    <FormFieldLabel label="File Server" is-mandatory />

    <div class="row q-col-gutter-md">
      <template v-if="appMode === 'CLOUD'">
        <div
          v-for="option in cloudFileServers"
          :key="option.id"
          class="col relative-position"
          :class="{ 'no-pointer-events': option.comingSoon }"
          @click="$emit('input', option.value)"
        >
          <div
            class="service"
            :class="{
              disabled: option.comingSoon,
              'font-medium': value === option.value,
            }"
          >
            <div v-if="option.comingSoon" class="coming-soon">coming soon</div>

            <Check v-if="value === option.value" />

            <img :src="_logo(option.logo)" :alt="option.label" />

            <div class="label">{{ option.label }}</div>
          </div>
        </div>
      </template>
      <template v-else-if="appMode === 'ON_PREMISE'">
        <div
          v-for="option in localFileServers"
          :key="option.id"
          class="col-3 relative-position"
          :class="{ 'no-pointer-events': option.comingSoon }"
          @click="$emit('input', option.value)"
        >
          <div
            class="service"
            :class="{
              disabled: option.comingSoon,
              'font-medium': value === option.value,
            }"
          >
            <div v-if="option.comingSoon" class="coming-soon">coming soon</div>

            <Check v-if="value === option.value" />

            <img :src="_logo(option.logo)" :alt="option.label" />

            <div class="label">{{ option.label }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import Check from "@/components/common/Check.vue";

export default {
  name: "CloudFileServers",

  components: { FormFieldLabel, Check },

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      cloudFileServers: [
        {
          id: this.$nano.id(),
          label: "ezofis Drive",
          value: "EZOFIS",
          logo: "ezofis",
          comingSoon: false,
        },
        {
          id: this.$nano.id(),
          label: "Azure Drive",
          value: "AZURE",
          logo: "azure",
          comingSoon: true,
        },
        {
          id: this.$nano.id(),
          label: "Goolge Drive",
          value: "GOOGLE_DRIVE",
          logo: "google",
          comingSoon: true,
        },
        {
          id: this.$nano.id(),
          label: "One Drive",
          value: "ONE_DRIVE",
          logo: "one",
          comingSoon: true,
        },
      ],
      localFileServers: [
        {
          id: this.$nano.id(),
          label: "EZOFIS Local",
          value: "LOCAL",
          logo: "ezofis",
          comingSoon: false,
        },
      ],
    };
  },

  computed: {
    ...mapState(["darkTheme"]),

    appMode() {
      return this.$store.state.session.appMode || "CLOUD";
    },
  },

  methods: {
    _logo(name) {
      return require(`@/assets/cloud-file-servers/${name}-drive-${
        this.darkTheme ? "dark" : "light"
      }.png`);
    },
  },
};
</script>

<style lang="scss" scoped>
#cloud-file-servers {
  .service {
    height: 136px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .coming-soon {
      font-size: 10px;
      color: var(--deep-orange);
      text-transform: uppercase;
      letter-spacing: 0.01em;
      position: absolute;
      top: 4px;
      font-weight: 600;
      text-align: center;
      width: 100%;
    }

    img {
      height: 48px;
      width: 48px;
    }

    .label {
      margin-top: 8px;
    }
  }
}
</style>
