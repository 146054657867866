<template>
  <FormSection
    :icon="isActive ? 'mdi-robot' : 'mdi-robot-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Auto Indexing Details</template>
    <template #description>
      Choose your preferred OCR indexing option to use as the default for your
      documents in this folder.
    </template>

    <!-- type -->

    <SingleChoiceField
      :value="indexingType"
      is-mandatory
      label="indexing type"
      tooltip="Standard OCR engines recognize content by zones and patterns, while advanced OCR engines use machine learning, natural language processing, and document classification for more accurate indexing of your documents"
      :options="filterIndexingTypes"
      :options-per-line="4"
      class="q-mb-lg"
      @input="updateType"
    />

    <div v-if="indexingType === 'ADVANCED' || indexingType === 'ANY'">
      <FormFieldLabel
        v-if="indexingType === 'ADVANCED' || indexingType === 'ANY'"
        :is-mandatory="true"
        label="Credits"
        tooltip="Free Credits: The available credits offer you a certain amount of advanced OCR processing without any additional cost. Each credit allows you to perform advanced indexing on a document within this folder. As you assign credits, you'll be able to utilize the advanced features of our OCR engine for more accurate document indexing."
      />

      <BaseSeparator />

      <div class="text-red q-mt-md">
        Available Credits: {{ indicator ? availableCredits : credits }}
      </div>

      <div class="row">
        <ValidationProvider
          v-if="indexingType === 'ADVANCED' || indexingType === 'ANY'"
          v-slot="{ errors }"
          name="Credits"
          :rules="{ min_value: 0, max_value: overallCredits }"
        >
          <CounterField
            v-if="indexingType === 'ADVANCED' || indexingType === 'ANY'"
            v-model="creditValue"
            :is-mandatory="true"
            label="Assign credits for this folder"
            placeholder="Credits of Advance indexing"
            :error="errors[0]"
            class="col-5 q-mt-md"
            @input="updateCreditLimit"
          />
        </ValidationProvider>
      </div>
    </div>

    <!-- ... -->
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import CounterField from "@/components/common/form/counter-field/CounterField.vue";

import { ValidationProvider } from "vee-validate";
import { repository } from "@/api/factory.js";

export default {
  name: "AutoIndexingDetails",

  components: {
    FormSection,
    SingleChoiceField,
    ValidationProvider,
    CounterField,
    FormFieldLabel,
  },

  props: {
    creditLimit: {
      type: Number,
      default: 0,
    },

    constantCredit: {
      type: Number,
      default: 0,
    },

    indexingType: {
      type: String,
      required: true,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    fieldsType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      indexingTypes: [
        {
          id: this.$nano.id(),
          label: "Manual",
          value: "MANUAL",
          disable: false,
        },
        {
          id: this.$nano.id(),
          label: "Standard",
          value: "STANDARD",
          disable: false,
        },
        {
          id: this.$nano.id(),
          label: "Advanced",
          value: "ADVANCED",
          disable: false,
        },
        {
          id: this.$nano.id(),
          label: "Any",
          value: "ANY",
          disable: false,
        },
      ],
      indicator: false,
      credits: 0,
      overallCredits: 0,
      availableCredits: 0,
      creditValue: 0,
    };
  },

  computed: {
    filterIndexingTypes() {
      if (this.fieldsType === "DYNAMIC") {
        return this.indexingTypes.filter((row) => row.value !== "MANUAL");
      }
      return this.indexingTypes;
    },
  },

  watch: {
    indexingType: {
      deep: true,
      immediate: true,
      handler() {
        if (this.indexingType === "ADVANCED" || this.indexingType === "ANY") {
          this.getOcrCredit();
        }
      },
    },

    constantCredit: {
      deep: true,
      immediate: true,
      handler() {
        this.creditValue = this.constantCredit;
      },
    },

    creditValue: {
      deep: true,
      immediate: true,
      handler() {
        this.updateAvailableCredits();
      },
    },
  },

  created() {},

  methods: {
    updateType(indexingType) {
      if (indexingType === "STANDARD") {
        this.creditValue = 0;
        this.$emit("update:creditLimit", this.creditValue);
      }
      this.$emit("update:indexingType", indexingType);
    },

    updateCreditLimit() {
      this.$emit("update:creditLimit", this.creditValue);
    },

    updateAvailableCredits() {
      if (this.creditValue > this.constantCredit) {
        const updatedCredits = this.creditValue;
        const existingCredits = this.constantCredit;
        const newCredits = updatedCredits - existingCredits;
        const availableCredit = this.credits;
        const newAvailableCredits = availableCredit - newCredits;
        this.indicator = true;
        this.availableCredits = Math.max(newAvailableCredits, 0);
      } else if (this.creditValue < this.constantCredit) {
        const updatedCredits = this.creditValue;
        const existingCredits = this.constantCredit;
        const newCredits = existingCredits - updatedCredits;
        const availableCredit = this.credits;
        const newAvailableCredits = availableCredit + newCredits;
        this.indicator = true;
        this.availableCredits = Math.max(newAvailableCredits, 0);
      } else this.indicator = false;
    },

    async getOcrCredit() {
      const id = 0;

      const { error, payload } = await repository.getOcrCredit(id);

      if (error) {
        this.$alert.error(error);
        return;
      }
      const numericPayload = parseFloat(payload);

      this.credits = numericPayload;

      this.overallCredits =
        this.creditLimit === 0
          ? numericPayload
          : numericPayload + this.creditLimit;
    },
  },
};
</script>

<style lang="scss" scoped></style>
