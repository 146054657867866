<template>
  <div id="auto_generated">
    <!-- allow decimals -->

    <FormFieldLabel label="Format" is-mandatory />
    <ValidationObserver ref="autoGenerated">
      <table class="q-mt-md">
        <thead>
          <tr>
            <th class="medium">Type</th>
            <th class="large">Value</th>
            <!-- <th class="large">Notify Users</th> -->
            <th class="action">
              <BaseActionButton
                is-flat
                class="q-ml-xs"
                color="secondary"
                icon="eva-plus-outline"
                no-border
                @click="addRow"
              />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, index) in autoGeneratedOptions" :key="index">
            <td>
              <template v-if="row.key === 'seperator'">
                <TextField
                  v-model="row.key"
                  class="col text-capitalize"
                  :is-clearable="true"
                  :is-readonly="row.key === 'seperator'"
                />
              </template>
              <template v-if="row.key !== 'seperator'">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="row.key"
                  class="col-12"
                  :rules="{ required: true }"
                >
                  <SelectField
                    v-model="row.key"
                    class="col"
                    :options="prefixList"
                    is-searchable
                    :error="errors[0]"
                    @input="selectedKey"
                  />
                </ValidationProvider>
              </template>
            </td>
            <td>
              <template v-if="row.key === 'prefix'">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="row.key"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="row.value"
                    class="col"
                    :is-clearable="true"
                    :error="errors[0]"
                    :placeholder="'enter the prefix'"
                  />
                </ValidationProvider>
              </template>
              <template v-if="row.key === 'version'">
                <TextField
                  v-model="row.value"
                  class="col"
                  value="1"
                  :is-clearable="true"
                  :is-readonly="true"
                />
              </template>
              <template v-if="row.key === 'autoIncrement'">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="row.key"
                  :rules="validationRules"
                >
                  <CounterField
                    v-model="row.value"
                    class="col"
                    :is-clearable="false"
                    :placeholder="'enter no.of digit'"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </template>

              <template v-if="row.key === 'fieldColumn'">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="row.key"
                  :rules="{ required: true }"
                >
                  <SelectField
                    v-model="row.value"
                    :options="fieldColumns"
                    is-searchable
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </template>

              <template v-if="row.key === 'seperator'">
                <SelectField
                  v-model="row.value"
                  class="col"
                  :options="seperator"
                  is-searchable
                />
              </template>

              <template v-if="row.key === 'currentDate'">
                <div class="col-12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="row.key"
                    :rules="{ required: true }"
                  >
                    <SelectField
                      v-model="row.value"
                      class="col-12"
                      :options="currentDates"
                      is-searchable
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </template>
            </td>

            <td class="action">
              <BaseActionButton
                v-if="row.key !== 'seperator'"
                is-flat
                color="red"
                icon="eva-close-outline"
                no-border
                @click="removeRow(index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </ValidationObserver>
    <div class="format row">
      <div class="col-auto q-mr-sm">Format :</div>
      <div
        v-for="(row, index) in autoGeneratedOptions"
        :key="index"
        class="row"
      >
        {{ getFormat(row, index) }}
      </div>
    </div>

    <!-- ... -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CounterField from "@/components/common/form/counter-field/CounterField.vue";

export default {
  name: "AutoGeneratedOptions",

  components: {
    ValidationProvider,
    TextField,
    SelectField,
    FormFieldLabel,
    CounterField,
    ValidationObserver,
  },

  props: {
    fieldColumns: {
      type: Array,
      default: () => [],
    },

    autoGeneratedOptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      prefix: [
        {
          id: this.$nano.id(),
          key: "seperator",
          value: "",
        },
        {
          id: this.$nano.id(),
          key: "",
          value: [],
        },
      ],
      prefixList: [
        {
          id: this.$nano.id(),
          label: "Prefix",
          value: "prefix",
        },
        {
          id: this.$nano.id(),
          label: "Field Column",
          value: "fieldColumn",
        },

        {
          id: this.$nano.id(),
          label: "Auto Increment",
          value: "autoIncrement",
        },
        {
          id: this.$nano.id(),
          label: "Version",
          value: "version",
        },
        {
          id: this.$nano.id(),
          label: "Current Date",
          value: "currentDate",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Seperator",
        //   value: "seperator",
        // },
      ],
      seperator: [
        // {
        //   id: this.$nano.id(),
        //   label: "/",
        //   value: "/",
        // },
        {
          id: this.$nano.id(),
          label: "-",
          value: "-",
        },
        {
          id: this.$nano.id(),
          label: "#",
          value: "#",
        },
        {
          id: this.$nano.id(),
          label: "_",
          value: "_",
        },
      ],
      currentDates: [
        {
          id: this.$nano.id(),
          label: "DDMMYY",
          value: "ddMMyy",
        },
        {
          id: this.$nano.id(),
          label: "DDMMYYYY",
          value: "ddMMyyyy",
        },
        {
          id: this.$nano.id(),
          label: "DDMMMYY",
          value: "ddMMMyy",
        },
        {
          id: this.$nano.id(),
          label: "DDMMMYYYY",
          value: "ddMMMyyyy",
        },
        {
          id: this.$nano.id(),
          label: "DDMMMMYY",
          value: "ddMMMMyy",
        },
        {
          id: this.$nano.id(),
          label: "DDMMMMYYYY",
          value: "ddMMMMyyyy",
        },
        {
          id: this.$nano.id(),
          label: "MMDDYY",
          value: "MMddyy",
        },
        {
          id: this.$nano.id(),
          label: "MMDDYYYY",
          value: "MMddyyyy",
        },
        {
          id: this.$nano.id(),
          label: "MMMDDYY",
          value: "MMMddyy",
        },
        {
          id: this.$nano.id(),
          label: "MMMDDYYYY",
          value: "MMMddyyyy",
        },
        {
          id: this.$nano.id(),
          label: "MMMMDDYY",
          value: "MMMMddyy",
        },
        {
          id: this.$nano.id(),
          label: "MMMMDDYYYY",
          value: "MMMMddyyyy",
        },
        {
          id: this.$nano.id(),
          label: "YYMMDD",
          value: "yyMMdd",
        },
        {
          id: this.$nano.id(),
          label: "YYYYMMDD",
          value: "yyyyMMdd",
        },
        {
          id: this.$nano.id(),
          label: "YYMMMDD",
          value: "yyMMMdd",
        },
        {
          id: this.$nano.id(),
          label: "YYYYMMMDD",
          value: "yyyyMMMdd",
        },
        {
          id: this.$nano.id(),
          label: "YYMMMMDD",
          value: "yyMMMMdd",
        },
        {
          id: this.$nano.id(),
          label: "YYYYMMMMDD",
          value: "yyyyMMMMdd",
        },
      ],
      currentIndex: 0,
    };
  },

  computed: {
    validationRules() {
      const rules = {
        integer: true,
        required: true,
        min_value: 1,
        max_value: 7,
      };

      return rules;
    },
  },

  watch: {
    autoGeneratedOptions: {
      deep: true,
      // immediate: true,
      async handler() {
        this.$emit("update:autoIncrement", this.autoGeneratedOptions);
      },
    },
  },

  methods: {
    removeRow(optionIdx) {
      this.autoGeneratedOptions.splice(optionIdx, 1);
    },

    addRow() {
      this.autoGeneratedOptions.push({
        id: this.$nano.id(),
        key: "",
        value: [],
      });
      this.currentIndex = this.autoGeneratedOptions.length - 1;
    },

    selectedKey() {
      if (this.autoGeneratedOptions[this.currentIndex].key === "version") {
        this.autoGeneratedOptions[this.currentIndex].value = "1";
      }
      if (this.autoGeneratedOptions[this.currentIndex].key === "prefix") {
        this.autoGeneratedOptions[this.currentIndex].value = "DOC";
      }
    },

    getFormat(data, index) {
      let string = "";
      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var monthShort = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var date = new Date();

      if (data.key === "prefix") {
        if (data.value) {
          string += `${data.value}${this.autoGeneratedOptions[0].value}`;
        }
      } else if (data.key === "fieldColumn") {
        if (data.value) {
          string += `${data.value}${this.autoGeneratedOptions[0].value}`;
          // var form = [];
          // data.value.forEach((col) => {
          //   if (col) {
          //     let column = this.fieldColumns.find((item) => item.value === col);
          //     if (column) {
          //       form.push(column.label);
          //     }
          //   }
          // });
          // if (form.length)
          //   string += `${form.join(`${this.autoGeneratedOptions[0].value}`)}${
          //     this.autoGeneratedOptions[0].value
          //   }`;
        }
      } else if (data.key === "autoIncrement") {
        if (data.value) {
          if (Number(data.value) <= 7) {
            var digit = "";
            for (let i = 1; i < data.value; i++) {
              digit += "0";
            }
            digit += "1";
            string += `${digit}${this.autoGeneratedOptions[0].value}`;
          }
        }
      } else if (data.key === "version") {
        string += `${data.value}${this.autoGeneratedOptions[0].value}`;
      } else if (data.key === "currentDate") {
        let day = (date.getDate() > 9 ? "" : "0") + date.getDate();
        let monthNum =
          (date.getMonth() + 1 > 9 ? "" : "0") + (date.getMonth() + 1);
        let year = date.getFullYear();
        switch (data.value) {
          case "ddMMyy":
            string += `${day}${monthNum}${year.toString().substring(2)}`;
            break;
          case "ddMMyyyy":
            string += `${day}${monthNum}${year}`;
            break;
          case "ddMMMyy":
            string += `${day}${monthShort[date.getMonth()]}${year
              .toString()
              .substring(2)}`;
            break;
          case "ddMMMyyyy":
            string += `${day}${monthShort[date.getMonth()]}${year}`;
            break;
          case "ddMMMMyy":
            string += `${day}${month[date.getMonth()]}${year
              .toString()
              .substring(2)}`;
            break;
          case "ddMMMMyyyy":
            string += `${day}${month[date.getMonth()]}${year}`;
            break;
          case "MMddyy":
            string += `${monthNum}${day}${year.toString().substring(2)}`;
            break;
          case "MMddyyyy":
            string += `${monthNum}${day}${year}`;
            break;
          case "MMMddyy":
            string += `${monthShort[date.getMonth()]}${day}${year
              .toString()
              .substring(2)}`;
            break;
          case "MMMddyyyy":
            string += `${monthShort[date.getMonth()]}${day}${year}`;
            break;
          case "MMMMddyy":
            string += `${month[date.getMonth()]}${day}${year
              .toString()
              .substring(2)}`;
            break;
          case "MMMMddyyyy":
            string += `${month[date.getMonth()]}${day}${year}`;
            break;
          case "yyMMdd":
            string += `${year.toString().substring(2)}${monthNum}${day}`;
            break;
          case "yyyyMMdd":
            string += `${year}${monthNum}${day}`;
            break;
          case "yyMMMdd":
            string += `${year.toString().substring(2)}${
              monthShort[date.getMonth()]
            }${day}`;
            break;
          case "yyyyMMMdd":
            string += `${year}${monthShort[date.getMonth()]}${day}`;
            break;
          case "yyMMMMdd":
            string += `${year.toString().substring(2)}${
              month[date.getMonth()]
            }${day}`;
            break;
          case "yyyyMMMMdd":
            string += `${year}${month[date.getMonth()]}${day}`;
            break;
        }
        string += this.autoGeneratedOptions[0].value;
      }
      let last = string.slice(-1);

      var format = "";
      if (index + 1 === this.autoGeneratedOptions.length) {
        if (last === this.autoGeneratedOptions[0].value) {
          format = string.substring(0, string.length - 1);
          // console.log(format);
        }
      } else {
        format += string;
      }
      return `${format}`;
    },
  },
};
</script>

<style lang="scss" scoped>
#auto_generated {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th:not(.action) {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.medium {
        width: 120px;
      }

      &.large {
        width: 140px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      &.action {
        width: 36px;
      }
    }

    td {
      padding: 4px;
      vertical-align: top;
    }
  }

  .format {
    @extend .text-base;
    color: var(--primary);
    margin-top: 4px;
    font-weight: bold;
  }
}
</style>
