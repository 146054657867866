<template>
  <div>
    <!-- select's options type -->

    <SingleChoiceField
      is-mandatory
      label="Options type"
      :value="optionsType"
      :options-per-line="1"
      :options="optionsTypes"
      class="q-mb-lg"
      @input="updateOptionsType"
    />

    <!-- ... -->

    <template v-if="optionsType === 'MASTER'">
      <!-- master table -->

      <ValidationProvider
        v-slot="{ errors }"
        name="master table"
        :rules="{ required: optionsType === 'MASTER' }"
      >
        <SelectField
          is-mandatory
          label="master table"
          :error="errors[0]"
          :value="masterFormId"
          :is-clearable="false"
          :options="masterForms"
          class="q-mb-lg"
          @input="updateMasterFormId"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- column -->

      <ValidationProvider
        v-slot="{ errors }"
        name="master table column"
        :rules="{ required: optionsType === 'MASTER' }"
      >
        <SelectField
          is-mandatory
          label="master table column"
          :error="errors[0]"
          :is-clearable="false"
          :value="masterTableColumn"
          :options="masterTableColumns"
          class="q-mb-lg"
          @input="updateMasterColumn"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- parent column -->
      <SelectField
        label="Parent Field (Filter based on parent field value selection)"
        placeholder="Parent Field"
        :value="masterFormParentColumn"
        :is-clearable="true"
        :options="fields"
        class="q-mb-lg"
        @input="updateMasterFormParentColumn"
      />
      <!-- ... -->
    </template>

    <!-- predefined options -->

    <ValidationProvider
      v-if="optionsType === 'PREDEFINED'"
      v-slot="{ errors }"
      name="predefined options"
      :rules="{ required: optionsType === 'PREDEFINED' }"
    >
      <OptionsCreatorField
        is-mandatory
        label="predefined options"
        :error="errors[0]"
        :value="predefinedOptions"
        class="q-mb-lg"
        @input="updatePredefinedOptions"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- add new options -->

    <CheckboxField
      v-if="false"
      label="Allow users to add new options"
      :value="allowToAddNewOptions"
      class="q-mb-md"
      @input="updateAllowToAddNewOptions"
    />

    <!-- ... -->

    <!-- enable control -->
    <div v-if="showMandatorySettings">
      <FormFieldLabel
        label="Enable mandatory fields based on the selected value"
        class="field-label"
        is-mandatory
      />
      <BaseScrollbar>
        <div>
          <table>
            <thead>
              <tr>
                <th class="medium">Value</th>
                <th class="large">Fields</th>
                <th class="action">
                  <BaseActionButton
                    is-flat
                    class="q-ml-xs"
                    color="secondary"
                    icon="eva-plus-outline"
                    no-border
                    @click="addRow"
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(row, index) in mandatorySettings" :key="index">
                <td>
                  <div class="row">
                    <SelectField
                      v-model="mandatorySettings[index]['value']"
                      class="col"
                      :options="options"
                      :is-clearable="false"
                      :new-option="optionsType === 'EXISTING'"
                    />
                  </div>
                </td>
                <td>
                  <MultiSelectField
                    v-model="mandatorySettings[index]['fields']"
                    :options="nonMandatoryFields"
                    :is-clearable="false"
                  />
                </td>
                <td class="action">
                  <BaseActionButton
                    is-flat
                    color="red"
                    icon="eva-close-outline"
                    no-border
                    @click="removeRow(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </BaseScrollbar>
    </div>
    <!-- ... -->
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import OptionsCreatorField from "@/components/common/form/select-field/OptionsCreatorField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import { form } from "@/api/factory.js";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import { axiosCrypto } from "@/api/axios.js";

export default {
  name: "SelectFieldOptions",

  components: {
    ValidationProvider,
    OptionsCreatorField,
    SelectField,
    SingleChoiceField,
    CheckboxField,
    FormFieldLabel,
    MultiSelectField,
  },

  props: {
    optionsType: {
      type: String,
      required: true,
    },

    masterFormId: {
      type: Number,
      required: true,
    },

    masterTableColumn: {
      type: String,
      required: true,
    },

    predefinedOptions: {
      type: Array,
      required: true,
    },

    allowToAddNewOptions: {
      type: Boolean,
      default: false,
    },

    masterFormParentColumn: {
      type: String,
      default: "",
    },

    fields: {
      type: Array,
      default: () => [],
    },

    field: {
      type: Object,
      default: () => {},
    },

    mandatorySettings: {
      type: Array,
      default: () => [],
    },

    nonMandatoryFields: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      optionsTypes: [
        {
          id: this.$nano.id(),
          label: "Use unique column values as options",
          value: "EXISTING",
        },
        {
          id: this.$nano.id(),
          label: "Use values from a master table as options",
          value: "MASTER",
        },
        {
          id: this.$nano.id(),
          label: "Use predefined values as options",
          value: "PREDEFINED",
        },
      ],
      masterForms: [],
      masterTableColumns: [],
      masterTableData: [],
    };
  },

  computed: {
    showMandatorySettings() {
      if (
        this.optionsType === "MASTER" &&
        this.masterFormId &&
        this.masterTableColumn
      ) {
        return true;
      } else if (
        this.optionsType === "PREDEFINED" &&
        this.predefinedOptions.length
      ) {
        return true;
      } else if (this.optionsType === "EXISTING") {
        return true;
      }
      return false;
    },

    options() {
      if (this.optionsType === "MASTER" && this.masterFormId) {
        return this.masterTableData;
      } else if (
        this.optionsType === "PREDEFINED" &&
        this.predefinedOptions.length
      ) {
        return this.predefinedOptions.map((field) => ({
          id: this.$nano.id(),
          label: field,
          value: field,
        }));
      }
      return [];
    },
  },

  watch: {
    optionsType: {
      immediate: true,
      handler() {
        switch (this.optionsType) {
          case "EXISTING":
            this.updateMasterFormId(0);
            this.updateMasterColumn("");
            this.updatePredefinedOptions([]);
            this.updateAllowToAddNewOptions(true);
            this.updateMasterFormParentColumn("");
            break;

          case "MASTER":
            this.updatePredefinedOptions([]);
            this.updateAllowToAddNewOptions(false);
            break;

          case "PREDEFINED":
            this.updateMasterFormId(0);
            this.updateMasterColumn("");
            this.updateAllowToAddNewOptions(false);
            this.updateMasterFormParentColumn("");
            break;
        }
      },
    },

    masterFormId: {
      immediate: true,
      handler() {
        if (this.masterFormId) {
          this.getFormFields(this.masterFormId);
        }
      },
    },

    mandatorySettings: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$emit("update:mandatorySettings", this.mandatorySettings);
      },
    },

    masterTableColumn: {
      immediate: true,
      handler() {
        if (this.masterTableColumn) {
          this.getMasterEntries(this.masterFormId, this.masterTableColumn);
        }
      },
    },
  },

  created() {
    this.getMasterForms();
  },

  mounted() {
    if (this.mandatorySettings.length === 0) {
      this.addRow();
    }
  },

  methods: {
    updateOptionsType(optionsType) {
      this.$emit("update:optionsType", optionsType);
    },

    updateMasterFormId(masterFormId) {
      this.$emit("update:masterFormId", masterFormId);
      // if (masterFormId) {
      //   this.getFormFields(masterFormId);
      // }
    },

    updateMasterColumn(masterTableColumn) {
      this.$emit("update:masterTableColumn", masterTableColumn);
    },

    updatePredefinedOptions(predefinedOptions) {
      this.$emit("update:predefinedOptions", predefinedOptions);
    },

    updateAllowToAddNewOptions(allowToAddNewOptions) {
      this.$emit("update:allowToAddNewOptions", allowToAddNewOptions);
    },

    async getMasterForms() {
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "MASTER",
                dataType: "",
              },
              {
                criteria: "publishOption",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 500,
        currentPage: 1,
        hasSecurity: false,
      });

      if (error) {
        this.$alert.error("Error fetching master form list");
        return;
      }

      this.masterForms = [];
      const { data } = payload;
      console.log(data);
      if (data.length) {
        this.masterForms = data[0].value.map((form) => ({
          id: this.$nano.id(),
          label: form.name,
          value: form.id,
        }));
      }
    },

    async getFormFields(formId) {
      this.masterTableColumns = [];
      const { error, payload } = await form.getForm(formId);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        let form = JSON.parse(payload.formJson);
        let masterfields = [];
        const panels = [...form.panels, ...form.secondaryPanels];

        if (!panels.length) {
          return;
        }
        for (const panel of panels) {
          masterfields.push(...panel.fields);
        }

        masterfields.forEach((field) => {
          if (field.type !== "DIVIDER") {
            this.masterTableColumns.push({
              id: field.id,
              label: field.label,
              value: field.id,
            });
          }
        });
      }
    },

    updateMasterFormParentColumn(masterFormParentColumn) {
      this.$emit("update:masterFormParentColumn", masterFormParentColumn);
    },

    addRow() {
      const model = {
        id: this.$nano.id(),
        value: "",
        fields: [],
      };

      this.mandatorySettings.push(model);
    },

    removeRow(rowIdx) {
      this.mandatorySettings.splice(rowIdx, 1);
    },

    async getMasterEntries(formId, column) {
      try {
        const response = await axiosCrypto.post(
          `/form/${formId}/uniqueColumnValues`,
          JSON.stringify({
            column: column,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }

        // this.options = data;
        const options = JSON.parse(data);
        if (options.length) {
          this.masterTableData = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  tr {
    height: 48px;
  }

  th:not(.action) {
    padding: 4px 8px;
    text-align: left;
    font-weight: 500;
    text-transform: capitalize;

    &.small {
      width: 80px;
    }

    &.medium {
      width: 150px;
    }

    &.large {
      width: 210px;
    }
  }

  th,
  td {
    border: 1px solid var(--divider-color);
    &.action {
      width: 36px;
    }
  }

  td {
    padding: 4px;
    vertical-align: top;
  }
}
</style>
