<template>
  <div id="table-preview">
    <FormFieldLabel label="preview" is-mandatory />

    <BaseScrollbar>
      <table>
        <thead>
          <tr>
            <th v-for="column in columns" :key="column._id">
              {{ column.name }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td v-for="column in columns" :key="column._id"></td>
          </tr>

          <tr>
            <td v-for="column in columns" :key="column._id">
              <div
                v-if="column.options.showColumnSum"
                class="q-ml-sm font-medium"
              >
                Total: 0
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </BaseScrollbar>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";

export default {
  name: "TablePreview",

  components: { FormFieldLabel },

  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#table-preview {
  table {
    table-layout: fixed;
    max-width: 100%;
    min-width: calc(100% - 1px);
    border-collapse: collapse;
    white-space: nowrap;

    tr {
      height: 36px;

      th {
        padding: 8px 8px 16px;
        color: var(--secondary);
        text-align: left;
        font-weight: 500;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
    }
  }
}
</style>
