<template>
  <Modal :value="value" width="540px" has-footer @input="closeModal">
    <!-- title -->

    <template #title>{{ column ? "Edit" : "Add" }} Column</template>

    <!-- ... -->

    <!-- column details -->

    <template #default>
      <ValidationObserver ref="form">
        <!-- name -->

        <ValidationProvider
          v-slot="{ errors }"
          name="name"
          :rules="{ required: true }"
        >
          <TextField
            v-model="name"
            label="name"
            is-mandatory
            :error="errors[0]"
            class="q-mb-lg"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- is mandatory -->

        <CheckboxField
          v-model="isMandatory"
          label="This is a mandatory field"
          class="q-mb-lg"
        />

        <!-- ... -->

        <!-- data type -->

        <SelectField
          v-model="dataType"
          label="data type"
          is-mandatory
          :is-clearable="false"
          :options="dataTypes"
          class="q-mb-lg"
        />

        <!-- ... -->

        <!-- data type's options -->

        <template v-if="dataType === 'NUMBER'">
          <CheckboxField
            v-model="options.showColumnSum"
            label="Show column sum"
            class="q-mb-md"
          />

          <NumberFieldOptions :allow-decimals.sync="options.allowDecimals" />
        </template>

        <BooleanFieldOptions
          v-if="dataType === 'BOOLEAN'"
          :truthy-value.sync="options.truthyValue"
          :falsy-value.sync="options.falsyValue"
        />

        <SelectFieldOptions
          v-if="dataType === 'SELECT'"
          :options-type.sync="options.optionsType"
          :master-table.sync="options.masterTable"
          :predefined-options.sync="options.predefinedOptions"
          :master-table-column.sync="options.masterTableColumn"
          :allow-to-add-new-options.sync="options.allowToAddNewOptions"
        />

        <BarcodeFieldOptions
          v-if="dataType === 'BARCODE'"
          :barcode-type.sync="options.barcodeType"
          :barcode-prefix.sync="options.barcodePrefix"
          :barcode-length.sync="options.barcodeLength"
        />

        <!-- ... -->
      </ValidationObserver>
    </template>

    <!-- ... -->

    <!-- footer -->

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="closeModal" />

      <BaseButton
        :label="column ? 'save' : 'add'"
        @click="column ? saveColumn() : addColumn()"
      />
    </template>

    <!-- ... -->
  </Modal>
</template>

<script>
import Modal from "@/components/common/popup/Modal.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import TextField from "@/components/common/form/text-field/TextField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";

import NumberFieldOptions from "../../../../NumberFieldOptions.vue";
import BooleanFieldOptions from "../../../../BooleanFieldOptions.vue";
import SelectFieldOptions from "../../../../SelectFieldOptions.vue";
import BarcodeFieldOptions from "../../../../BarcodeFieldOptions.vue";

import { lowerCase, startCase } from "lodash-es";

export default {
  name: "TableColumnDetails",

  components: {
    Modal,
    ValidationObserver,
    ValidationProvider,
    TextField,
    CheckboxField,
    SelectField,
    NumberFieldOptions,
    BooleanFieldOptions,
    SelectFieldOptions,
    BarcodeFieldOptions,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    column: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isColumnDetailsVisible: false,
      name: "",
      dataType: "TEXT",
      isMandatory: false,
      options: {
        allowDecimals: true,
        showColumnSum: "",
        truthyValue: "true",
        falsyValue: "false",
        optionsType: "EXISTING",
        masterTable: "",
        masterTableColumn: "",
        predefinedOptions: [],
        allowToAddNewOptions: true,
        barcodeType: "",
        barcodePrefix: "",
        barcodeLength: "",
      },
    };
  },

  computed: {
    dataTypes() {
      return [
        "SHORT_TEXT",
        "NUMBER",
        "BOOLEAN",
        "DATE",
        "TIME",
        "DATE_TIME",
        "SINGLE_SELECT",
        "BARCODE",
      ].map((type) => ({
        id: this.$nano.id(),
        label: startCase(lowerCase(type)),
        value: type,
      }));
    },
  },

  watch: {
    column: {
      immediate: true,
      handler() {
        if (this.column) {
          this.name = this.column.name;
          this.isMandatory = this.column.isMandatory;
          this.dataType = this.column.dataType;
          this.options = this.column.options;
        } else {
          this.reset();
        }
      },
    },
  },

  methods: {
    reset() {
      this.name = "";
      this.dataType = "TEXT";
      this.isMandatory = false;
      this.options = {
        allowDecimals: true,
        showColumnSum: false,
        truthyValue: "true",
        falsyValue: "false",
        optionsType: "EXISTING",
        masterTable: "",
        masterTableColumn: "",
        predefinedOptions: [],
        allowToAddNewOptions: true,
        barcodeType: "",
        barcodePrefix: "",
        barcodeLength: "",
      };
    },

    closeModal() {
      this.$emit("input", false);
      this.reset();
    },

    async addColumn() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      this.$emit("add", {
        _id: this.$nano.id(),
        name: this.name,
        dataType: this.dataType,
        isMandatory: this.isMandatory,
        options: this.options,
      });

      this.closeModal();
    },

    async saveColumn() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }
      this.$emit("save", {
        _id: this.column._id,
        name: this.name,
        dataType: this.dataType,
        isMandatory: this.isMandatory,
        options: this.options,
      });

      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped></style>
