<template>
  <FormSection
    :icon="isActive ? 'mdi-file-replace' : 'mdi-file-replace-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Document Version Details</template>
    <template #description>
      Choose a naming convention suffix that suits your preference to identify
      and manage multiple versions of files.
    </template>

    <!-- version type -->

    <SingleChoiceField
      is-mandatory
      label="version type"
      tooltip="By selecting a suffix, the system will automatically assign the same suffix to each new version of the file. This enables you to conveniently track revisions and maintain proper version control for your documents."
      :value="versionType"
      :options-per-line="1"
      :options="versionTypes"
      class="q-mb-lg"
      @input="updateVersionType"
    />

    <!-- ... -->

    <!-- file version -->

    <SingleChoiceField
      is-mandatory
      label="versioned documents"
      tooltip="By selecting this option, you can choose whether to display all versions of a document or only the latest version in the digital workspace."
      :options-per-line="1"
      :value="versionedDocuments"
      :options="versionedDocumentOptions"
      @input="updateVersionedDocuments"
    />

    <!-- ... -->
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";

export default {
  name: "VersionDetails",

  components: { FormSection, SingleChoiceField },

  props: {
    versionType: {
      type: String,
      required: true,
    },

    versionedDocuments: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },
  },

  data() {
    return {
      versionTypes: [
        {
          id: this.$nano.id(),
          label: "Replace",
          value: "REPLACE",
          description: "eg. file_name",
        },
        {
          id: this.$nano.id(),
          label: "Timestamp",
          value: "TIMESTAMP",
          description: "eg. file_name_YYYY-MM-DD HH:MM:SS",
        },
        {
          id: this.$nano.id(),
          label: "Number",
          value: "NUMBER",
          description: "eg. file_name_1",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Alphabet",
        //   value: "ALPHABET",
        //   description: "eg. file_name_A",
        // },
      ],
      versionedDocumentOptions: [
        {
          id: this.$nano.id(),
          label: "Show all the versioned documents",
          value: "SHOW",
        },
        {
          id: this.$nano.id(),
          label: "Show only the latest document",
          value: "HIDE",
        },
      ],
    };
  },

  methods: {
    updateVersionType(versionType) {
      this.$emit("update:versionType", versionType);
    },

    updateVersionedDocuments(versionedDocuments) {
      this.$emit("update:versionedDocuments", versionedDocuments);
    },
  },
};
</script>

<style lang="scss" scoped></style>
