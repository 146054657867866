import { render, staticRenderFns } from "./ColumnBuilder.vue?vue&type=template&id=6058816f&scoped=true&"
import script from "./ColumnBuilder.vue?vue&type=script&lang=js&"
export * from "./ColumnBuilder.vue?vue&type=script&lang=js&"
import style0 from "./ColumnBuilder.vue?vue&type=style&index=0&id=6058816f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6058816f",
  null
  
)

export default component.exports