<template>
  <FormSection
    :icon="isActive ? 'mdi-folder' : 'mdi-folder-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Fields Details</template>
    <template #description>
      Configure the fields / tags that you would like to use for indexing
      documents, making it easier to save and search them in the future
    </template>

    <!-- type -->

    <SingleChoiceField
      :value="fieldsType"
      is-mandatory
      label="fields type"
      tooltip="Organize the content within the repository, making it easier to manage and search for specific information"
      :options="fieldTypes"
      :options-per-line="4"
      :is-readonly="mode === 'EDIT'"
      class="q-mb-lg"
      @input="updateType"
    />

    <!-- ... -->

    <!-- fields -->

    <ValidationProvider
      v-slot="{ errors }"
      name="fields"
      :rules="{ required: true }"
    >
      <FieldsBuilder
        :value="fields"
        :fields-type="fieldsType"
        :error="errors[0]"
        class="q-mb-lg"
        @input="updateFields"
      />
    </ValidationProvider>

    <!-- ... -->
  </FormSection>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FormSection from "@/components/common/item-builder/FormSection.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import FieldsBuilder from "./components/fields-builder/FieldsBuilder.vue";

export default {
  name: "FieldsDetails",

  components: {
    FormSection,
    SingleChoiceField,
    ValidationProvider,
    FieldsBuilder,
  },

  props: {
    fieldsType: {
      type: String,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    dynamicFields: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      fieldTypes: [
        {
          id: this.$nano.id(),
          label: "Dynamic",
          value: "DYNAMIC",
        },
        {
          id: this.$nano.id(),
          label: "Static",
          value: "STATIC",
        },
      ],
    };
  },

  watch: {
    fieldsType() {
      if (this.fieldsType === "DYNAMIC") {
        this.updateFields(this.dynamicFields);
      } else {
        this.updateFields([]);
      }
    },
  },

  methods: {
    updateType(fieldsType) {
      this.$emit("update:fieldsType", fieldsType);
    },

    updateFields(fields) {
      // set field level
      let fieldLevel = 0;
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].includeInFolderStructure) {
          fieldLevel += 1;
          fields[i].level = fieldLevel;
        }
      }
      this.$emit("update:fields", fields);
    },
  },
};
</script>

<style lang="scss" scoped></style>
