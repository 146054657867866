<template>
  <div id="barcode-field-options">
    <!-- barcode type -->

    <ValidationProvider
      v-slot="{ errors }"
      name="barcode type"
      :rules="{ required: true }"
    >
      <SelectField
        is-mandatory
        label="barcode type"
        :error="errors[0]"
        :value="barcodeType"
        :is-clearable="false"
        :options="barcodeTypes"
        class="q-mb-lg"
        @input="updateBarcodeType"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- barcode prefix -->

    <ValidationProvider
      v-slot="{ errors }"
      name="barcode prefix"
      :rules="{ required: true }"
    >
      <TextField
        is-mandatory
        label="barcode prefix"
        :error="errors[0]"
        :value="barcodePrefix"
        class="q-mb-lg"
        @input="updateBarcodePrefix"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- barcode length -->

    <ValidationProvider
      v-slot="{ errors }"
      name="barcode length"
      :rules="{ required: true, integer: true }"
    >
      <NumberField
        is-mandatory
        label="barcode length"
        :error="errors[0]"
        :value="barcodeLength"
        class="q-mb-lg"
        @input="updateBarcodeLength"
      />
    </ValidationProvider>

    <!-- ... -->
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";

export default {
  name: "BarcodeFieldOptions",

  components: { ValidationProvider, SelectField, TextField, NumberField },

  props: {
    barcodeType: {
      type: String,
      required: true,
    },

    barcodePrefix: {
      type: String,
      required: true,
    },

    barcodeLength: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      barcodeTypes: [
        {
          id: this.$nano.id(),
          label: "Quick Response (QR) Codes",
          value: "Quick Response (QR) Codes",
        },
        {
          id: this.$nano.id(),
          label: "Universal Product Codes (UPC)",
          value: "Universal Product Codes (UPC)",
        },
        {
          id: this.$nano.id(),
          label: "Data Matrix",
          value: "Data Matrix",
        },
      ],
    };
  },

  methods: {
    updateBarcodeType(barcodeType) {
      this.$emit("update:barcodeType", barcodeType);
    },

    updateBarcodePrefix(barcodePrefix) {
      this.$emit("update:barcodePrefix", barcodePrefix);
    },

    updateBarcodeLength(barcodeLength) {
      this.$emit("update:barcodeLength", barcodeLength);
    },
  },
};
</script>

<style lang="scss" scoped></style>
