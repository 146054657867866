<template>
  <div id="table-field-options">
    <!-- column builder -->

    <ValidationProvider
      v-slot="{ errors }"
      name="columns"
      mode="lazy"
      :rules="{ required: false }"
    >
      <ColumnBuilder
        :value="columns"
        :error="errors[0]"
        @input="updateColumn"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- table preview -->

    <TablePreview v-if="columns.length" :columns="columns" />

    <!-- ... -->
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";

import ColumnBuilder from "./components/column-builder/ColumnBuilder.vue";
import TablePreview from "./components/TablePreview.vue";

export default {
  name: "TableFieldOptions",

  components: {
    ValidationProvider,
    ColumnBuilder,
    TablePreview,
  },

  props: {
    columns: {
      type: Array,
      required: true,
    },
  },

  methods: {
    updateColumn(columns) {
      this.$emit("update:columns", columns);
    },
  },
};
</script>

<style lang="scss" scoped></style>
