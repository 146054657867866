var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"cloud-file-servers"}},[_c('FormFieldLabel',{attrs:{"label":"File Server","is-mandatory":""}}),_c('div',{staticClass:"row q-col-gutter-md"},[(_vm.appMode === 'CLOUD')?_vm._l((_vm.cloudFileServers),function(option){return _c('div',{key:option.id,staticClass:"col relative-position",class:{ 'no-pointer-events': option.comingSoon },on:{"click":function($event){return _vm.$emit('input', option.value)}}},[_c('div',{staticClass:"service",class:{
            disabled: option.comingSoon,
            'font-medium': _vm.value === option.value,
          }},[(option.comingSoon)?_c('div',{staticClass:"coming-soon"},[_vm._v("coming soon")]):_vm._e(),(_vm.value === option.value)?_c('Check'):_vm._e(),_c('img',{attrs:{"src":_vm._logo(option.logo),"alt":option.label}}),_c('div',{staticClass:"label"},[_vm._v(_vm._s(option.label))])],1)])}):(_vm.appMode === 'ON_PREMISE')?_vm._l((_vm.localFileServers),function(option){return _c('div',{key:option.id,staticClass:"col-3 relative-position",class:{ 'no-pointer-events': option.comingSoon },on:{"click":function($event){return _vm.$emit('input', option.value)}}},[_c('div',{staticClass:"service",class:{
            disabled: option.comingSoon,
            'font-medium': _vm.value === option.value,
          }},[(option.comingSoon)?_c('div',{staticClass:"coming-soon"},[_vm._v("coming soon")]):_vm._e(),(_vm.value === option.value)?_c('Check'):_vm._e(),_c('img',{attrs:{"src":_vm._logo(option.logo),"alt":option.label}}),_c('div',{staticClass:"label"},[_vm._v(_vm._s(option.label))])],1)])}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }