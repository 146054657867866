<template>
  <div>
    <!-- allow decimals -->

    <CheckboxField
      :value="allowDecimals"
      label="Allow decimals"
      class="q-mb-lg"
      @input="updateAllowDecimals"
    />

    <!-- ... -->
  </div>
</template>

<script>
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";

export default {
  name: "NumberFieldOptions",

  components: { CheckboxField },

  props: {
    allowDecimals: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    updateAllowDecimals(allowDecimals) {
      this.$emit("update:allowDecimals", allowDecimals);
    },
  },
};
</script>

<style lang="scss" scoped></style>
