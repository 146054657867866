<template>
  <div id="options-creator">
    <FormFieldLabel
      :label="label"
      :is-mandatory="isMandatory"
      :tooltip="tooltip"
    />

    <FormFieldWrapper
      ref="wrapper"
      :is-focused="isFocused"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
    >
      <!-- add option -->

      <div class="add-option">
        <div class="col">
          <input
            v-model="option"
            type="text"
            placeholder="Type and press enter to add options"
            @focus="isFocused = true"
            @blur="isFocused = false"
            @keypress.enter="addOption"
          />
        </div>

        <BaseIcon
          name="eva-plus"
          :color="option ? 'secondary' : 'gray'"
          class="cursor-pointer"
          @click="addOption"
        />
      </div>

      <!-- ... -->

      <!-- options -->

      <div v-if="value.length" class="options">
        <div v-for="(_option, index) in value" :key="_option" class="chip">
          <div class="q-mr-sm">{{ index + 1 }}. {{ _option }}</div>

          <BaseIcon
            name="eva-close"
            class="cursor-pointer"
            @click="removeOption(index)"
          />
        </div>
      </div>

      <!-- ... -->
    </FormFieldWrapper>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import { lowerCase } from "lodash-es";

import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "OptionsCreatorField",

  components: { FormFieldLabel, FormFieldWrapper, FormFieldError },

  props: {
    value: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isFocused: false,
      option: "",
    };
  },

  methods: {
    addOption() {
      if (!this.option) {
        return;
      }

      const isOptionAlreadyExists = this.value.find(
        (_option) => lowerCase(_option) === lowerCase(this.option)
      );

      if (isOptionAlreadyExists) {
        this.$alert.error("Option already exists");
        return;
      }

      this.$emit("input", [...this.value, this.option]);
      this.option = "";
    },

    removeOption(optionIdx) {
      const options = this.value;
      options.splice(optionIdx, 1);
      this.$emit("input", options);
    },
  },
};
</script>

<style lang="scss" scoped>
#options-creator {
  .add-option {
    display: flex;
    align-items: center;
    height: 46px;
    padding: 0px 8px;
  }

  .options {
    border-top: 1px solid var(--border-color);
    margin: 0px 8px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .chip {
      display: flex;
      align-items: center;
      padding: 4px 8px;
      border-radius: 4px;
      margin-top: 8px;
      margin-right: 8px;
      font-weight: 500;
      background-color: var(--component-bg-color-inferior);
    }
  }
}
</style>
