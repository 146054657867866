<template>
  <FormSection
    :icon="isActive ? 'mdi-database' : 'mdi-database-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Storage Details</template>
    <template #description>
      Choose the storage location where you want to save all documents and files
      associated with your selected digital workspace
    </template>

    <!-- cloud file server -->

    <CloudFileServers
      :value="cloudFileServer"
      class="q-mb-lg"
      @input="updateCloudFileServer"
    />

    <!-- ... -->

    <!-- <OrDivider v-if="appMode === 'ON_PREMISE'" /> -->

    <!-- local file server path -->

    <ValidationProvider
      v-if="appMode === 'ON_PREMISE1'"
      v-slot="{ errors }"
      name="local file server path"
      :rules="{ required: true }"
    >
      <TextField
        :value="localFileServerPath"
        label="local file server path"
        tooltip="File location for storing the documents"
        is-mandatory
        :is-readonly="mode === 'EDIT'"
        :error="errors[0]"
        @input="updateLocalFileServerPath"
      />
    </ValidationProvider>

    <!-- ... -->
  </FormSection>
</template>

<script>
import FormSection from "@/components/common/item-builder/FormSection.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
//import OrDivider from "@/components/common/OrDivider.vue";
import CloudFileServers from "./components/CloudFileServers.vue";

import { ValidationProvider } from "vee-validate";

export default {
  name: "StorageDetails",

  components: {
    FormSection,
    ValidationProvider,
    TextField,
    CloudFileServers,
    // OrDivider,
  },

  props: {
    cloudFileServer: {
      type: String,
      required: true,
    },

    localFileServerPath: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },
  },

  computed: {
    appMode() {
      return this.$store.state.session.appMode || "CLOUD";
    },
  },

  methods: {
    updateCloudFileServer(cloudFileServer) {
      this.$emit("update:cloudFileServer", cloudFileServer);
    },

    updateLocalFileServerPath(localFileServerPath) {
      this.$emit("update:localFileServerPath", localFileServerPath);
    },
  },
};
</script>

<style lang="scss" scoped></style>
