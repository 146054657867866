<template>
  <div id="column-builder">
    <!-- label -->

    <FormFieldLabel label="columns" is-mandatory />

    <!-- ... -->

    <!-- header -->

    <div class="header">
      <div class="label col">Name</div>
      <div class="label" style="width: 120px">Data Type</div>
      <div class="label" style="width: 120px">Is Mandatory</div>
      <div class="" style="width: 80px">
        <BaseActionButton
          is-flat
          color="secondary"
          icon="eva-plus"
          no-border
          @click="isColumnDetailsVisible = true"
        />
      </div>
    </div>

    <!-- ... -->

    <!-- columns -->

    <Draggable :value="value" @input="handleChange">
      <div
        v-for="(_column, index) in value"
        :key="_column._id"
        class="_column row items-center"
      >
        <BaseIcon name="drag_indicator" size="16px" class="q-mr-sm" />

        <div class="col">{{ _column.name }}</div>

        <div style="width: 120px">{{ _column.dataType }}</div>

        <div style="width: 120px">{{ _column.isMandatory ? "Yes" : "No" }}</div>
        <div class="row" style="width: 80px">
          <BaseActionButton
            is-flat
            color="secondary"
            icon="eva-edit-outline"
            no-border
            class="col-auto"
            @click="editColumn(_column)"
          />

          <BaseActionButton
            is-flat
            color="red"
            icon="eva-close-outline"
            no-border
            class="col-auto"
            @click="removeColumn(index)"
          />
        </div>
      </div>
    </Draggable>

    <!-- ... -->

    <BaseSeparator v-if="value.length" />

    <!-- error -->

    <FormFieldError v-if="error" :error="error" />

    <!-- ... -->

    <!-- column details -->

    <ColumnDetails
      v-model="isColumnDetailsVisible"
      :column="column"
      @add="addColumn"
      @save="saveColumn"
    />

    <!-- ... -->
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import Draggable from "@/components/common/Draggable.vue";
import ColumnDetails from "./components/ColumnDetails.vue";

export default {
  name: "ColumnBuilder",

  components: { FormFieldLabel, FormFieldError, Draggable, ColumnDetails },

  props: {
    value: {
      type: Array,
      required: true,
    },

    error: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      column: null,
      isColumnDetailsVisible: false,
    };
  },

  watch: {
    isColumnDetailsVisible() {
      if (!this.isColumnDetailsVisible) {
        this.column = null;
      }
    },
  },

  methods: {
    handleChange(columns) {
      this.$emit("input", columns);
    },

    clearColumns() {
      this.handleChange([]);
    },

    addColumn(column) {
      this.handleChange([...this.value, column]);
    },

    editColumn(column) {
      this.column = column;
      this.isColumnDetailsVisible = true;
    },

    saveColumn(column) {
      const columns = this.value;
      const columnIdx = this.value.findIndex(
        (_column) => _column._id === column._id
      );

      columns[columnIdx] = column;
      this.handleChange(columns);
    },

    removeColumn(columnIdx) {
      const columns = this.value;
      columns.splice(columnIdx, 1);
      this.handleChange(columns);
    },
  },
};
</script>

<style lang="scss" scoped>
#column-builder {
  margin-bottom: 24px;

  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 4px;
    margin-bottom: 12px;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }

  ._column {
    margin-bottom: 8px;
    cursor: move;
    position: relative;
  }

  .drag-indicator {
    position: absolute;
    left: -28px;
  }
}
</style>
